
@import 'partials/colors';

html {
	background-color: #fff0d1;
	font-family: 'Alegreya Sans', sans-serif;
}

.h1-container {
	position: absolute;
	bottom: 0;
}
h1 {
	font-weight: 300;
	font-size: 250%;
	position: relative;
	color: #6d7fa1;
	text-shadow: 2px 2px 7px #999;
	margin: 0.2em 0;
}
h1.white {
	color: white;
}
p {
	margin: 1em 0 0 0;
}
p.indented {
	margin: 1em 0 0 1em;
}

.hide-on-mobile {
	display: block;
}
.display-on-mobile {
	display: none;
}

#secure {
	display: block;
	background-color: white;
	text-align: right;
	padding: 0.2em 1em;
}

.container {

}
.centred {
	text-align: center;
}

.bg1 {
	/*background-color: #666;*/
	background-image: url('/img/water-reflexion.jpg');
	background-size: cover;
}
.bg2 {
	background-color: #555;
}
.bg4 {
	background-color: #6d7fa1;
}
.bg3 {
	background-color: white;
}

img.header_logo {
	width: 200px;
}
img.header_400 {
	width: 110px;
}

.home-bg {
	position: relative;
	height: 280px;
	background: url('/img/DAH_0398.jpg') no-repeat;

	background-position: center center;
	background-attachment: scroll;

	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.home-ad-panel {
	background-color: #fff0d1;
	h2 {
		font-weight: 200;
		font-style: italic;
		background-color: #6d7fa1;
		color: #ffffff!important;
		padding: 0 0.5em 0 0.5em;
		margin: 1em 0 0 0;
	}
	p {
		padding: 0 1em 0.25em 1em;
	}
	padding-bottom: 1em;
	margin: 0 0 2em 0;
}

.bx-wrapper {
    border: 0!important;
    padding: 0!important;
    -moz-box-shadow: 0!important;
    -webkit-box-shadow: 0!important;
    box-shadow: 0!important;
}
.bx-viewport {
    border: 0!important;
    margin: 1em 0 0 0.5em!important;
    padding: 0!important;
    -moz-box-shadow: 0!important;
    -webkit-box-shadow: 0!important;
    box-shadow: 0!important;
}
.bxslider {
    border: 0!important;
    margin: 0 0 0 0!important;
    padding: 0!important;

}



.content {
	margin: 0;
	margin: auto;
	max-width: 1024px;
	padding: 0;
}

.header-left {
	float: left;
	padding: 0.5em 0 0.5em 0;
}
.header-right {
	float: right;
	padding: 0.5em 0 0.5em 0;
}

.alert {
	margin: 1.0em 0;
	padding: 0.7em 0.7em 0.7em 0.7em;
	line-height: 130%;
}
.alert-danger {
	background-color: red;
	color: white;
}
.alert-success {
	background-color: $brandColor;
}
.formfield-error {
	background-color: red;
	color: white;
	padding: 0.3em 0.7em 0.4em 0.7em;
}

.section-menu {
	float: left;
	width: 25%;
	padding: 0 0 0.5em 0;
}
.section-menu ul {
	list-style-type: none;
	padding: 0;
	margin-top: 0.3em;
}
.section-menu ul li a {
	display: inline-block;
	text-decoration: none;
	padding: 0.25em 0.5em 0.25em 0;
	color: #918259;
}
.section-menu ul li a:hover {
	color: #999;
}

.section-menu ul li a:active, .section-menu ul li a.active {
	color: #6d7fa1;
}
.section-menu-images {
	padding: 0 1.0em 1.0em 0;
	img {
		width: 100%;
	}
}






.home-feature {
	margin: 1em 1em 1.5em 0;

	a {
		text-decoration: none;
	}

	.home-feature-heading {
		background-color: #6d7fa1;
		color: white;
		text-align: center;
		font-size: 110%;
		padding: 5px;


	}
	.home-feature-content img {
		width: 100%;
	}
}
.crest-container {
	position: relative;
	img {
		display: block;
		margin: 0 auto;
	}
}


.section-content {
	float: right;
	width: 75%;
	padding: 0 0 2em 0;

	// img {
	// 	width: 40%;
	// }
	img[align="left"],
	img[style*="float: left"],
	img[style*="float:left"] {
		margin:5px 20px 10px 0!important;
	}
	img[align="right"],
	img[style*="float: right"],
	img[style*="float:right"] {
		margin:5px 0 10px 20px!important;
	}
	img[align="center"],
	img[style*="float: center"],
	img[style*="float:center"] {
		display: block;
		width: 100%;
		margin:5px 0 20px 20px!important;
	}

	.document-row img {
		width: auto;
		position: absolute;
		left: 0.5em;
		top: 0.5em;
	}

	.bx-wrapper img {
		width: auto;
	}
}

.section-content h2 {
	font-size: 170%;
	color: #6d7fa1;
}
.section-content hr {
	margin: 2em 0 0 0;
	color: #6d7fa1;
}
.section-random-images {
	float: left;
	margin: 1em 1em 0.5em 0;
}





.content-panel {
	background-color: #ddd;
	padding: 0.25em 1em 1em 1em;
	text-align: center;
	margin: 1em 0;
}

.content-left {
	float: left;
	width: 50%;
}
.content-right {
	float: right;
	width: 50%;
}

.innertube {
	padding: 0 0 1em 0;
}
.innertube-with-padding-right {
	padding: 0 1em 1em 0;
}

img.embedded-image {
	width: 300px;
	margin: 1em 0.5em 0.5em 0.5em;
}


.document-row {
	position: relative;
	width: auto;
	background-color: #ddd;
	padding: 0.5em 0.5em 0.5em 3em;
	margin: 0.5em 0;
	min-height: 2em;
}
.document-row a {
	text-decoration: none;
	color: #918259;
}
.document-row a:hover {
	color: #999;
}








.news-row {
	padding: 1.0em 0 1em 0;
	border-top: 1px solid #ccc;
	margin-bottom: 1.5em;
}
.news-row:first-child {
	padding: 0.6em 0 1em 0;
	border-top: 0;
}

.news-listing-left {
	float: left;
	width: 35%;
	padding: 0.4em 0 0.5em 0;
}
.news-listing-left-picture {
	padding: 0 1.0em 0.5em 0;
	img {
		width: 100%;
	}
}
.news-listing-right {
	float: right;
	width: 65%;

	h2 {
		font-size: 170%;
		line-height: 120%;
		color: #6d7fa1;
		margin: 0;
	}
}

.news-listing-fullwidth {
	h2 {
		font-size: 170%;
		line-height: 120%;
		color: #6d7fa1;
		margin: 0;
	}
}

a.read-more {
	background-color: #918259;
	color: white;
	border: 0;
	padding: 5px 10px;
	text-decoration: none;
}
a.read-more:hover {
	background-color: #999;
}







form.dac-form {
	margin-top: 1em;
	max-width: 500px;
}
label {
	color: #6d7fa1;
}
.dac-form input[type="text"],
.dac-form input[type="password"],
.dac-form textarea {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;

	margin: 0;
	padding: 5px;
	border: 1px solid #6d7fa1;
}
input[type="text"],
input[type="password"] {
	line-height: 120%;
	min-height: 30px;
}
input[type="submit"] {
	background-color: #918259;
	color: white;
	border: 0;
	padding: 5px 10px;
}
input[type="submit"]:hover {
	background-color: #999;
}
.form-row {
	clear: both;
}


table {
	width: 100%;
	margin-top: 1em;
}
table tr {
	vertical-align: top;
}
table th {
	text-align: left;
	background-color: #ccc;
}
table th, table td {
	padding: 0.5em;
	border-bottom: 1px solid white;
}
table td {
	background-color: #ddd;
}

footer {
	color: #6d7fa1;
	margin-bottom: 2em;
}
footer a {
	text-decoration: none;
	color: #918259;
}
footer a:hover {
	color: #999;
}


#nav {
	margin: 0;
	padding: 0;
	text-align: center;
	z-index: 1000;
}


/* mobile icon... */
#nav > a {
	display: none;
}
/* /mobile icon... */

#nav li {
	position: relative;
}
#nav li a {
	color: #999;
	display: block;
	padding: 5px 15px;
	text-decoration: none;
}
#nav li a:active, #nav li a.active {
	background-color: #666 !important;
	color: #ccc;
}



/* first level */

#nav > ul
{
	display: inline-block;
	vertical-align:top;
	margin: 0;
	padding: 0;
	clear: both;
	background-color: #555;
	list-style-type: none;
}
#nav > ul > li
{
	height: 100%;
	float: left;
}
#nav > ul > li > a
{
	background-color: #555;
	display: inline-block;
	height: 100%;
	font-size: 1.0em; /* 24 */
	line-height: 1.5em; /* 60 (24) */
	text-align: center;

}
#nav > ul > li:not( :last-child ) > a
{
	border-right: 1px solid #666;
}
#nav > ul > li:hover > a,
#nav > ul:not( :hover ) > li.active > a
{
	background-color: #999;
	color: white;
}


/* second level */

#nav li ul
{
	background-color: #666;
	display: none;
	position: absolute;
	list-style-type: none;
	width: 200px;
	margin: 0;
	padding: 0;
	z-index: 1000;
}
#nav li:hover ul
{
	display: block;
	/*left: 0;
	right: 0;*/
}
#nav li:not( :first-child ):hover ul
{
	left: -1px;
}

#nav li ul li
{
	margin: 0;
	padding: 0;
	text-align: left;
}


#nav li ul a
{
	font-size: 1.0em; /* 20 */
	border-top: 1px solid #999;
	padding: 5px 15px;
	color: #ccc;
}
#nav li ul li a:hover,
#nav li ul:not( :hover ) li.active a
{
	background-color: #999;
	color: white;
}

.sitemap a {
	color: #918259;
	text-decoration: none;
}
.sitemap a:hover {
	color: #999;
	text-decoration: underline;
}


@media only screen and ( max-width: 62.5em ) /* 1000 */
{
	#nav
	{
		width: 100%;
		position: static;
		margin: 0;
	}

	.logo-container {
		padding: 2em 1em 1em 1em;
	}

	h1 {
		font-size: 175%;
	}

	.section-content {
		img {
			width: 40%;
		}
		img[align="left"],
		img[style*="float: left"],
		img[style*="float:left"] {
			margin:5px 20px 10px 0!important;
		}
		img[align="right"],
		img[style*="float: right"],
		img[style*="float:right"] {
			margin:5px 0 10px 20px!important;
		}
		img[align="center"],
		img[style*="float: center"],
		img[style*="float:center"] {
			display: block;
			width: 100%;
			margin:5px 0 10px 20px!important;
		}
	}

}
@media only screen and ( max-width: 64.5em ) /* 1000 */
{
	.content {
		padding: 0 1em;
	}

	.logo-container {
		padding: 2em 1em 1em 1em;
	}







	.news-row {
		padding: 0.6em 0.5em 2em 0;
	}

	.news-listing-left {
		float: left;
		width: 35%;
		padding: 0.4em 0 0.5em 0;


	}
	.news-listing-left-picture {
		padding: 0 1.0em 0.5em 0;
		img {
			width: 100%;
		}
	}
	.news-listing-right {
		float: right;
		width: 65%;


		h2 {
			font-size: 170%;
			color: #6d7fa1;
			margin: 0;
		}
	}





}


@media only screen and ( max-width: 40em ) /* 640 */
{


	h1 {
		font-size: 150%;
	}

	img.embedded-image {
		width: 200px;
	}

	#nav
	{
		position: relative;
		top: 0;
		left: auto;
		/*text-align: right;*/
	}

	#nav li:hover ul
	{
		display: none;
		/*left: 0;
		right: 0;*/
	}

	/* mobile icon... */
	#nav > a
	{
		width: 2.125em; /* 50 */
		height: 2.125em; /* 50 */
		text-align: left;
		text-indent: 2.125em;
		background-color: #918259;
		position: relative;
		text-decoration: none;
	}
	#nav > a span
	{
		// margin-top: 2px;
		color: white;
		font-size: 18px;
		font-style: bold;
		text-decoration: none;
		line-height: 35px;
		width: 100%;
		padding: 0 40px 8px 12px;
	}
	#nav > a:hover {
		background-color: #999;
	}
	#nav > a:before,
	#nav > a:after
	{
		position: absolute;
		border: 2px solid #fff;
		top: 35%;
		left: 25%;
		right: 25%;
		content: '';
	}
	#nav > a:after
	{
		top: 60%;
	}
	/* /mobile icon... */


	#nav:not( :target ) > a:first-of-type,
	#nav:target > a:last-of-type
	{
		display: block;
	}


	/* first level */

	#nav > ul
	{
		height: auto;
		display: none;
		position: absolute;
		left: -1em;
		right: 0;
		padding: 0;
		width: 100%;
	}
	#nav:target > ul
	{
		display: block;
	}
	#nav > ul > li
	{
		width: 100%;
		float: none;
	}
	#nav > ul > li > a
	{
		height: auto;
		width: 100%;
		text-align: left;
		padding: 0.5 1.0em; /* 20 (24) */
		border-right: 3px solid #555;
	}
	#nav > ul > li:not( :last-child ) > a
	{
		border-right: 3px solid #555;
		border-bottom: 1px solid #666;

	}


	/* second level */

	#nav li ul
	{
		position: static;
		padding: 0;
		width: 100%;
	}




	.logo-container {
		padding: 2em 1em 1em 1em;
	}
	.logo {
		font-size: 120%;
		letter-spacing: 0.1em;
	}


	.section-menu {
		float: none;
		display: block;
		width: 100%;
		padding: 0.5em 0 0 0;
	}

	.section-menu-images {
		padding: 0 0 1.0em 0;
		img {
			width: 100%;
		}
	}

	.section-content {
		float: none;
		display: block;
		width: 100%;
		padding: 0 0 2em 0;

		// img {
		// 	width: 40%;
		// }
		img[align="left"],
		img[style*="float: left"],
		img[style*="float:left"] {
			margin:5px 20px 10px 0!important;
		}
		img[align="right"],
		img[style*="float: right"],
		img[style*="float:right"] {
			margin:5px 0 10px 20px!important;
		}
		img[align="center"],
		img[style*="float: center"],
		img[style*="float:center"] {
			display: block;
			width: 100%;
			margin:5px 0 50px 20px!important;
		}
	}

	.home-feature {
		margin: 0 0 1.5em 0;
	}

	.hide-on-mobile {
		display: none;
	}
	.display-on-mobile {
		display: block;
	}

}

@media only screen and ( max-width: 30em )
{
	.home-feature {
		margin: 0 0 1.5em 0;
	}

	img.embedded-image {
		width: 100%;
		margin: 1em 0 0.5em 0!important;
	}

	img.header_logo {
		width: 150px;
	}
	img.header_400 {
		width: 85px;
	}

	.section-content {
		img {
			width: 100%;
		}
	}

	.news-listing-left {
		padding: 0.2em 0 0.5em 0;
	}
	.news-listing-right {
		h2 {
			font-size: 140%;
		}
	}
}


/* DAC specific Photoswipe styles */
.my-gallery {
  /*width: 100%;*/
  float: left;
  margin-top: 1em;
}
.my-gallery img {
  width: 100%;
  height: auto;
  border: 1px solid #6d7fa1;
}
.my-gallery figure {
  display: block;
  float: left;
  margin: 0 25px 25px 0;
  width: 200px;
}
.my-gallery figcaption {
  display: none;
}

.pswp__caption__center {
	text-align: center!important;
	max-width: 90%;
	font-size: 110%;
}

/* .DAC specific Photoswipe styles */
