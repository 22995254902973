
$background-page: white;
$container-background: white;

$brandColor: rgb(0,33,125);
$brandColorLight: rgb(102,204,255);

$qcd-yellow: rgb(222,189,111);
$qcd-cream: rgb(245,239,206);
$qcd-grey: rgb(133,131,122);

$main-font-colour: white;
$labelColour: rgb(200,200,200);

$heading-font-colour: blue;
$background-intro: gray;

// $member-pic-border: rgb(40,40,40);

$footer-text-colour: rgb(40,40,40);



// $background-page: white;
// $container-background: white;

// $brandColor: rgb(0,33,125);
// $brandColorLight: rgb(102,204,255);

// $qcd-yellow: rgb(222,189,111);
// $qcd-cream: rgb(245,239,206);
// $qcd-grey: rgb(133,131,122);

// $main-font-colour: red;

// $heading-font-colour: blue;
// $background-intro: gray;